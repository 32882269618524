<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <!-- <div class="inline mr-8">
                    <el-select v-model="filters.type" clearable placeholder="缴费/退费" size="small">
                        <el-option label="缴费" value="1"></el-option>
                        <el-option label="退费" value="2"></el-option>
                    </el-select>
                </div> -->
                <div class="inline mr-8">
                    <el-select v-model="filters.fundType" clearable placeholder="费用类型" size="small">
                        <el-option label="租金" value="0"></el-option>
                        <el-option label="押金" value="1"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.paymentType" clearable placeholder="支付方式" size="small">
                        <el-option label="现金" value="2"></el-option>
                        <el-option label="招行卡" value="4"></el-option>
                        <el-option label="扫码" value="5"></el-option>
                        <el-option label="民生卡" value="6"></el-option>
                        <el-option label="对公转账" value="7"></el-option>
                        <el-option label="银行转账" value="1"></el-option>
                        <el-option label="网络支付" value="3"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-input v-model="filters.remark" placeholder="备注" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-date-picker
                        v-model="filters.date"
                        type="date"
                        placeholder="支付日期"
                        value-format="yyyy-MM-dd"
                        size="small">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div v-if="auth.add" class="topbar">
                    <div v-if="auth.add" class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd(1)" :disabled="$store.state.org.type != 2">缴费</el-button>
                    </div>
                    <!-- <div class="inline mr-8">
                        <el-button type="warning" size="small" @click="handleAdd(2)" :disabled="$store.state.org.type != 2">退费</el-button>
                    </div> -->
                    <!-- <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> -->
                </div>
                <div class="datatable">
                    <el-table
                        ref="curChargeTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            prop="paymentNo"
                            width="136"
                            label="缴费单号">
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="type"
                            label="缴费/退费"
                            width="90">
                            <template slot-scope="scope">
                                <el-tag
                                    :type="scope.row.type === 1 ? '' : 'warning'"
                                    disable-transitions>{{scope.row.type === 1 ? '缴费' : '退费'}}</el-tag>
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="payin"
                            label="金额"
                            align="left"
                            width="100">
                            <template slot-scope="scope">
                                <template v-if="scope.row.type === 1">
                                    {{ scope.row.payin }}
                                </template>
                                <template v-else>
                                    {{ scope.row.payout }}
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="fundType"
                            align="center"
                            label="费用类型"
                            width="80">
                            <template slot-scope="scope">
                                <template v-if="scope.row.fundType === 0">
                                    租金
                                </template>
                                <template v-if="scope.row.fundType === 1">
                                    押金
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="paymentType"
                            align="center"
                            label="支付方式"
                            width="80">
                            <template slot-scope="scope">
                                <template v-if="scope.row.paymentType === 2">
                                    现金
                                </template>
                                <template v-if="scope.row.paymentType === 4">
                                    招行卡
                                </template>
                                <template v-if="scope.row.paymentType === 5">
                                    扫码
                                </template>
                                <template v-if="scope.row.paymentType === 6">
                                    民生卡
                                </template>
                                <template v-if="scope.row.paymentType === 7">
                                    对公转账
                                </template>
                                <template v-if="scope.row.paymentType === 1">
                                    银行转账
                                </template>
                                <template v-if="scope.row.paymentType === 3">
                                    网络支付
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="date"
                            align="center"
                            label="支付日期"
                            width="110">
                        </el-table-column>
                        <el-table-column
                            prop="userName"
                            align="center"
                            label="登记人"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="createTime"
                            align="center"
                            label="登记时间"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="remark"
                            align="center"
                            label="备注"
                            width="100">
                            <template slot-scope="scope">
                                <el-popover
                                    placement="top-start"
                                    trigger="hover"
                                    :content="scope.row.remark"
                                    popper-class="remark-tip">
                                    <div slot="reference" class="remark-box">{{ scope.row.remark }}</div>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="auth.edit || auth.delete"
                            prop="action"
                            label="操作"
                            align="center"
                            width="150">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="auth.edit"
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    v-if="auth.delete"
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                :title="addDialog.title"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ChargeAdd :params="addDialog.params" @done="handleAddDone"></ChargeAdd>
            </el-dialog>

            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ChargeEdit :params="editDialog.params" @done="handleEditDone"></ChargeEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import ChargeAdd from './ChargeAdd'
import ChargeEdit from './ChargeEdit'

export default {
    name: 'CostCharge',
    components: {
        ChargeAdd,
        ChargeEdit
    },
    data() {
        return {
            supplierList: [],

            filters: {
                supplierId: '',
                type: '',
                paymentType: '',
                fundType: '',
                remark: '',
                date: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,

            addDialog: {
                title: '',
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
            auth: {
                add: false,
                delete: false,
                edit: false,
            },
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
        
        this.auth.add = this.hasPermission('cost:charge:add');
        this.auth.delete = this.hasPermission('cost:charge:delete');
        this.auth.edit = this.hasPermission('cost:charge:edit');
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.projectId = this.$store.state.org.id;
            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;
            params.type = 1;

            this.tableLoading = true;
            http.get('v1/payment/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data.records;
                    data.forEach(item => {
                        item.payin = this.toFixed(item.payin, 2);
                        item.payout = this.toFixed(item.payout, 2);
                        item.createTime = item.createTime.substring(0, 16);
                    });
                    this.tableData = data;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 添加弹窗口
        handleAdd(type) {
            let title = type == 1 ? "缴费" : "退费";
            this.addDialog = { title: title, create: true, visible: false, params: { type: type }  };
            this.$nextTick(() => {
                this.addDialog = { title: title, create: true, visible: true, params: { type: type }  };
            });
        },
        handleAddDone() {
            this.addDialog = { title: this.addDialog.title, visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { title: this.addDialog.title, create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDelete(index, row) {
            this.confirm('确认删除？', function () {
                let data = {
                    id : row.id
                };
                http.post('v1/payment/delete', data).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
    }
}
</script>

<style scoped>
</style>
