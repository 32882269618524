<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24" class="col-1">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small" @change="handleSupplierChange">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div class="print-box">
                        <el-button
                            size="small"
                            type="warning"
                            :disabled="form.supplierId == ''"
                            @click="handlePrint()">打印</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="总押金：" class="mix-item">
                        {{ data.deposit }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总租金：" class="mix-item">
                        {{ data.rent }}
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="datatable-inner">
                <el-form-item label="" label-width="0" prop="billList">
                    <div class="datatable">
                        <el-table
                            ref="curBillTable"
                            :data="data.billList"
                            v-loading="tableLoading"
                            element-loading-background="rgba(255, 255, 255, 0.3)"
                            border
                            style="width: 100%">
                            <el-table-column
                                label="序号"
                                align="center"
                                width="80">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="paidNo"
                                label="单号"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="month"
                                label="账单月份"
                                min-width="100">
                            </el-table-column>
                            <el-table-column
                                prop="rent"
                                label="租金"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="fine"
                                label="罚款金额"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="cutPayment"
                                label="扣款金额"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="escalation"
                                label="调差金额"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="paidMoney"
                                label="账单金额"
                                min-width="120">
                            </el-table-column>
                            <el-table-column
                                prop="isPaid"
                                label="状态"
                                min-width="120">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.isPaid == 1" type="success" disable-transitions>已支付</el-tag>
                                    <el-tag v-if="scope.row.isPaid == 0" type="danger" disable-transitions>未支付</el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </div>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="押金余额：" class="mix-item">
                        {{ data.surplusDeposit }}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="租金余额：" class="mix-item">
                        {{ data.surplusRent }}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="总余额：" class="mix-item">
                        {{ data.surplusTotal }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="支付类型" prop="paymentType">
                        <el-select v-model="form.paymentType" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in paymentTypeList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付日期" prop="date">
                        <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'LastRefund',
    data(){
        return {
            paymentTypeList: [
                {  id: 2, name:"现金" },
                {  id: 4, name:"招行卡" },
                {  id: 5, name:"扫码" },
                {  id: 6, name:"民生卡" },
                {  id: 7, name:"对公转账" },
                {  id: 1, name:"银行转账" },
                {  id: 3, name:"网络支付" },
            ],
            supplierList: [],
            data: {
                deposit: "-",
                rent: "-",
                surplusDeposit: "-",
                surplusRent: "-",
                surplusTotal: "-",
                billList: [],
            },
            form: {
                supplierId: '',
                date: '',
                paymentType: '',
                remark: '',
                billList: [],
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'change'}],
                billList: [
                    {required: true, validator: this.checkBillList, trigger: 'change'},
                ],
                paymentType: [{required: true, message: '支付方式不能为空', trigger: 'change'}],
                date: [{required: true, message: '支付日期不能为空', trigger: 'blur'}],
            },
            tableLoading: false,
            submitting: false,
        }
    },
    created() {
        this.loadSupperList();
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            let params = {};
            params.status = 1;
            http.get("v1/supplier/list", { params: params }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSupplierChange() {
            this.loadBillData();
        },
        loadBillData() {
            let params = {};
            params.id = this.form.supplierId;
            this.tableLoading = true;
            http.get("v1/payment/totalBill", { params: params }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    if (!data) {
                        this.data = {
                            deposit: "-",
                            rent: "-",
                            surplusDeposit: "-",
                            surplusRent: "-",
                            surplusTotal: "-",
                            billList: [],
                        };
                        this.form.billList = [];
                        this.$refs.form.validateField("billList");
                        return;
                    }

                    data.billList.forEach(item => {
                        item.rent = this.toFixed(item.rent, 2);
                        item.fine = this.toFixed(item.fine, 2);
                        item.cutPayment = this.toFixed(item.cutPayment, 2);
                        item.escalation = this.toFixed(item.escalation, 2);
                    });
                    this.form.billList = data.billList;
                    this.data = data;
                    this.$refs.form.validateField("billList");
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};

                    data.projectId = this.$store.state.org.id;
                    data.supplierId = this.form.supplierId;

                    data.type = 2;
                    data.payin = 0;
                    data.payout = 0;

                    data.paymentType = this.form.paymentType;
                    data.date = this.form.date;
                    data.remark = this.form.remark;

                    data.billIdList = [];
                    this.form.billList.forEach(x => data.billIdList.push(x.id));

                    http.post('v1/payment/last', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        checkBillList (rule, value, callback) {
            for(let i = 0; i < this.form.billList.length; i++) {
                let bill = this.form.billList[i];
                if (!bill.isPaid) {
                    callback(new Error('存在未支付账单，不能完成末次结算'));
                    return;
                }
            }

            callback();
        },
        resetForm() {
            this.form.supplierId = '';
            this.form.billList = [];
            this.data = {
                deposit: "-",
                rent: "-",
                surplusDeposit: "-",
                surplusRent: "-",
                surplusTotal: "-",
                billList: [],
            };
            this.$nextTick(() => {
                this.$refs.form.clearValidate("supplierId");
                this.$refs.form.clearValidate("billList");
            });
        },
        handlePrint() {
            for(let i = 0; i < this.form.billList.length; i++) {
                let bill = this.form.billList[i];
                if (!bill.isPaid) {
                    this.showError('存在未支付账单，不能打印末次结算单');
                    return;
                }
            }

            let data = { ... this.data };
            let supplier = this.supplierList.find(x=>x.id == this.form.supplierId);
            if (supplier) {
                data.supplier = supplier;
            }
            data.project = this.$store.state.org;
            
            this.$emit("print", data);
        },
    }
};
</script>

<style scoped>
.datatable-inner { 
    margin-left:-11px;
    margin-right:-11px;
}
.no-checkout {
    color: #e00;
}
.datatable-inner /deep/ .el-form-item__error {
    margin-left: 25px;
}
.print-box {
    position: absolute;
    top: 5px;
    right: 0;
}
.col-1 {
    position: relative;
}
</style>