<template>
    <div class="dialog-inner" style="padding:0;">
        <div class="page-sep">No.1</div>
        <div class="print-page">
            <h2 class="page-title">末次结算单</h2>
            <div class="page-table-box">
                <table border="0" cellpadding="0" cellspacing="0" class="page-table">
                    <tr>
                        <td class="col-t">项目名称</td>
                        <td class="col-c">{{ detail.projectName }}</td>
                        <td class="col-t">分包单位</td>
                        <td class="col-c">{{ detail.supplierName }}</td>
                    </tr>
                    <tr>
                        <td class="col-t">总押金</td>
                        <td class="col-c"><span v-if="detail.deposit != ''">&yen;</span>{{ detail.deposit }}</td>
                        <td class="col-t">总租金</td>
                        <td class="col-c"><span v-if="detail.rent != ''">&yen;</span>{{ detail.rent }}</td>
                    </tr>
                    <tr>
                        <td class="col-t">押金余额</td>
                        <td class="col-c"><span v-if="detail.surplusDeposit != ''">&yen;</span>{{ detail.surplusDeposit }}</td>
                        <td class="col-t">租金余额</td>
                        <td class="col-c"><span v-if="detail.surplusRent != ''">&yen;</span>{{ detail.surplusRent }}</td>
                    </tr>
                    <tr>
                        <td class="col-t">总余额</td>
                        <td class="col-c"><span v-if="detail.surplusTotal != ''">&yen;</span>{{ detail.surplusTotal }}</td>
                        <td class="col-t">大写</td>
                        <td class="col-c">{{ detail.totalText }}</td>
                    </tr>
                </table>
            </div>
            <div class="page-sign clearfix">
                <div class="page-sign-1">打印时间：{{ curDate }}</div>
                <div class="page-sign-2">打印人：{{ userName }}</div>
                <div class="page-sign-3">签字确认：<span class="gap"></span></div>
            </div>
        </div>
        <div v-for="(page, index) in pageList" :key="index">
            <div class="page-sep">No.{{ index + 2 }}</div>
            <div class="print-break"></div>
            <div class="print-page">
                <h2 class="page-title">月度账单</h2>
                <div class="page-table-box">
                    <table border="0" cellpadding="0" cellspacing="0" class="page-table">
                        <tr>
                            <td class="col3-t col3-no">序号</td>
                            <td class="col3-t-no">单号</td>
                            <td class="col3-t-m">账单月份</td>
                            <td class="col3-t">租金</td>
                            <td class="col3-t">罚款金额</td>
                            <td class="col3-t">扣款金额</td>
                            <td class="col3-t">调差金额</td>
                            <td class="col3-t">账单金额</td>
                        </tr>
                        <tr v-for="item in page.list" :key="item.id">
                            <td class="col3-c">{{ item.index }}</td>
                            <td class="col3-c col3-c-no">{{ item.paidNo }}</td>
                            <td class="col3-c col3-c-m">{{ item.month }}</td>
                            <td class="col3-c">&yen;{{ item.rent }}</td>
                            <td class="col3-c">&yen;{{ item.fine }}</td>
                            <td class="col3-c">&yen;{{ item.cutPayment }}</td>
                            <td class="col3-c">&yen;{{ item.escalation }}</td>
                            <td class="col3-c">&yen;{{ item.paidMoney }}</td>
                        </tr>
                    </table>
                </div>
                <div class="page-sign clearfix">
                    <div class="page-sign-1">打印时间：{{ curDate }}</div>
                    <div class="page-sign-2">打印人：{{ userName }}</div>
                    <div class="page-sign-3">签字确认：<span class="gap"></span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/utils/http'
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'CostBillPrint',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            detail: {
                date: '',
                projectName: '',
                supplierName: '',
                deposit: '',
                rent: '',

                surplusDeposit: '',
                surplusRent: '',

                surplusTotal: '',

                totalText: '',
            },
            curDate: '',
            userName: '',
            billList: [],
            pageRowCount: 32,
            pageList: [],
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            let detail = {}
            detail.date = this.getLocalDate();

            let data = this.params.data;
            if (this.params.type == 1) {
                this.loadData(data);
            } else {
                if (data.project) {
                    detail.projectName = data.project.name;
                }

                if (data.supplier) {
                    detail.supplierName = data.supplier.name;
                }

                detail.deposit = data.deposit;
                detail.rent = data.rent;

                detail.surplusDeposit = data.surplusDeposit;
                detail.surplusRent = data.surplusRent;

                detail.surplusTotal = data.surplusTotal;

                let total = new BigDecimal(detail.surplusTotal);
                let maxCurrency = new BigDecimal('9999999999999999.9999');
                if (total.compareTo(maxCurrency) <= 0) {
                    detail.totalText = this.convertCurrency(detail.surplusTotal);
                } else {
                    this.showError('总余额数值过大');
                }

                this.detail = detail;

                this.billList = data.billList;

                this.curDate = this.getLocalTime();
                
                let userInfo = this.getUserInfo();
                this.userName = userInfo.name;

                this.loadDone();
            }
        },
        loadData(data) {
            this.dataLoading = true;
            let loading = this.load();
            http.get('v1/payment/lastRefund', { params: { id: data.lastRefundId } }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let detail = {}
                    detail.date = this.getLocalDate();

                    let data = this.params.data;
                    
                    let reqData = req.data.data;
                    if (data.projectName) {
                        detail.projectName = data.projectName;
                    }

                    if (data.supplierName) {
                        detail.supplierName = data.supplierName;
                    }

                    detail.deposit = reqData.deposit;
                    detail.rent = reqData.rent;

                    detail.surplusDeposit = reqData.surplusDeposit;
                    detail.surplusRent = reqData.surplusRent;

                    detail.surplusTotal = reqData.surplusTotal;

                    let total = new BigDecimal(detail.surplusTotal);
                    let maxCurrency = new BigDecimal('9999999999999999.9999');
                    if (total.compareTo(maxCurrency) <= 0) {
                        detail.totalText = this.convertCurrency(detail.surplusTotal);
                    } else {
                        this.showError('总余额数值过大');
                    }

                    this.detail = detail;

                    this.billList = reqData.billList;

                    this.curDate = this.getLocalTime();
                    
                    let userInfo = this.getUserInfo();
                    this.userName = userInfo.name;

                    this.loadDone();
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        loadDone() {
            let pageRowCount = this.pageRowCount;
            let pageList = [];
            let index = 0;
            let page;
            this.billList.forEach(item => {
                if (item.rule === 0 || item.rule === 3) {
                    return;
                }

                index++;

                if (index % pageRowCount == 1) {
                    page = {type: 1, list: []};
                    pageList.push(page);
                }

                page.list.push({
                    index: index,
                    paidNo: item.paidNo,
                    month: item.month,
                    rent: this.toFixed(item.rent, 2),
                    fine: this.toFixed(item.fine, 2),
                    cutPayment: this.toFixed(item.cutPayment, 2),
                    escalation: this.toFixed(item.escalation, 2),
                    paidMoney: this.toFixed(item.paidMoney, 2),
                });
            });

            this.pageList = pageList;
        },
        print() {
            window.print();
        },
    }
};
</script>

<style scoped>
.page-no {
    min-height: 1px;
}
</style>
