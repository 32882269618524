<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="金额" prop="money">
                        <el-input type="text" v-model="form.money" size="small" maxlength="15" @blur="fieldTrim(form, 'money')"></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12">
                    <el-form-item label="费用类型" prop="fundType">
                        <el-select v-model="form.fundType" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in fundTypeList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="支付类型" prop="paymentType">
                        <el-select v-model="form.paymentType" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in paymentTypeList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付日期" prop="date">
                        <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'CostChargeEdit',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            supplierList: [],
            paymentTypeList: [
                {  id: 2, name:"现金" },
                {  id: 4, name:"招行卡" },
                {  id: 5, name:"扫码" },
                {  id: 6, name:"民生卡" },
                {  id: 7, name:"对公转账" },
                {  id: 1, name:"银行转账" },
                {  id: 3, name:"网络支付" },
            ],
            fundTypeList: [
                {  id: 0, name:"租金" },
                {  id: 1, name:"押金" },
            ],
            form: {
                supplierId: '',
                money: '',
                date: '',
                paymentType: '',
                fundType: '',
                remark: '',
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'blur'}],
                money: [
                    {required: true, message: '金额不能为空', trigger: 'blur'},
                    {required: true, validator: this.checkMoney, trigger: 'blur'}
                ],
                paymentType: [{required: true, message: '支付方式不能为空', trigger: 'change'}],
                fundType: [{required: true, message: '费用类型不能为空', trigger: 'change'}],
                date: [{required: true, message: '支付日期不能为空', trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        this.loadSupperList();
        this.init();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            this.form.supplierId = this.params.supplierId;
            if (this.params.type == 1) {
                this.form.money = this.params.payin;
            } else if (this.params.type == 2) {
                this.form.money = this.params.payout;
            } else {
                this.showError("未知类型");
                return;
            }
            this.form.date = this.params.date;
            this.form.paymentType = this.params.paymentType;
            this.form.fundType = this.params.fundType;
            this.form.remark = this.params.remark;
        },
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.id = this.params.id;
                    data.projectId = this.$store.state.org.id;
                    data.supplierId = this.form.supplierId;
                    if (this.params.type == 1) {
                        data.type = 1;
                        data.payin = this.form.money;
                        data.payout = 0;
                    } else if (this.params.type == 2) {
                        data.type = 2;
                        data.payin = 0;
                        data.payout = this.form.money;
                    } else {
                        this.showError("未知类型");
                        return;
                    }
                    
                    data.paymentType = this.form.paymentType;
                    data.date = this.form.date;
                    data.remark = this.form.remark;

                    http.post('v1/payment/edit', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
    }
};
</script>

<style scoped>
</style>