<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small" @change="handleSupplierChange">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="月度系数" prop="monthOffset">
                        <el-select v-model="form.monthOffset" placeholder="请选择" size="small" @change="handleSupplierChange">
                            <el-option 
                                v-for="item in monthOffsetList" 
                                :key="item.value" 
                                :label="item.name" 
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="最大可退租金：" class="mix-item">
                        {{ data.freeRent }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="最大可退押金：" class="mix-item">
                        {{ data.freeDeposit }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="租金金额" prop="rent">
                        <el-input type="text" v-model="form.rent" size="small" maxlength="15" @blur="fieldTrim(form, 'rent')"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="押金金额" prop="deposit">
                        <el-input type="text" v-model="form.deposit" size="small" maxlength="15" @blur="fieldTrim(form, 'deposit')"></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12">
                    <el-form-item label="费用类型" prop="fundType">
                        <el-select v-model="form.fundType" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in fundTypeList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="支付类型" prop="paymentType">
                        <el-select v-model="form.paymentType" placeholder="请选择" size="small">
                            <el-option 
                                v-for="item in paymentTypeList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付日期" prop="date">
                        <el-date-picker
                            v-model="form.date"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" size="small" maxlength="500" @blur="fieldTrim(form, 'remark')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from "js-big-decimal"

export default {
    name: 'CostRefundAdd',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            supplierList: [],
            monthOffsetList: [
                { value: 1, name: '1个月' },
                { value: 3, name: '3个月' },
                { value: 6, name: '6个月' },
                { value: 9, name: '9个月' },
                { value: 12, name: '12个月' },
            ],
            paymentTypeList: [
                {  id: 2, name:"现金" },
                {  id: 4, name:"招行卡" },
                {  id: 5, name:"扫码" },
                {  id: 6, name:"民生卡" },
                {  id: 7, name:"对公转账" },
                {  id: 1, name:"银行转账" },
                {  id: 3, name:"网络支付" },
            ],
            fundTypeList: [
                {  id: 0, name:"租金" },
                {  id: 1, name:"押金" },
            ],
            data: {
                freeDeposit: '-',
                freeRent: '-',
            },
            form: {
                supplierId: '',
                money: '',
                date: '',
                paymentType: '',
                fundType: '',
                remark: '',
                rent: '',
                deposit: '',
                monthOffset: 6,
            },
            rules: {
                supplierId: [{required: true, message: '分包单位不能为空', trigger: 'change'}],
                rent: [
                    { validator: this.checkRent, trigger: 'blur'},
                ],
                deposit: [
                    { validator: this.checkDeposit, trigger: 'blur'},
                ],
                paymentType: [{required: true, message: '支付方式不能为空', trigger: 'change'}],
                fundType: [{required: true, message: '费用类型不能为空', trigger: 'change'}],
                date: [{required: true, message: '支付日期不能为空', trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        this.loadSupperList();
    },
    methods: {
        ...common,
        ...verify,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {};
                    data.projectId = this.$store.state.org.id;
                    data.supplierId = this.form.supplierId;
                    
                    // if (this.params.type == 1) {
                    //     data.type = 1;
                    //     data.payin = this.form.money;
                    //     data.payout = 0;
                    // } else if (this.params.type == 2) {
                    data.type = 2;
                    data.payin = 0;
                    data.payout = 0;
                    data.rent = this.form.rent;
                    data.deposit = this.form.deposit;
                    // } else {
                    //     this.showError("未知类型");
                    //     return;
                    // }

                    data.paymentType = this.form.paymentType;
                    // data.fundType = this.form.fundType;
                    data.date = this.form.date;
                    data.remark = this.form.remark;

                    http.post('v1/payment/add', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleSupplierChange() {
            this.loadBalanceData();
        },
        loadBalanceData() {
            if (this.form.supplierId === '' || this.form.supplierId === null) {
                return;
            }

            let params = {};
            params.id = this.form.supplierId;
            params.monthOffset = this.form.monthOffset;
            this.data = {
                deposit: "-",
                rent: "-",
                surplusDeposit: '-',
                surplusRent: '-',
                freeDeposit: '-',
                freeRent: '-',
            };
            http.get("v1/payment/balance", { params: params }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    if (!data) {
                        this.data = {
                            deposit: "-",
                            rent: "-",
                            surplusDeposit: '-',
                            surplusRent: '-',
                            freeDeposit: '-',
                            freeRent: '-',
                        };
                        this.$refs.form.validateField("rent");
                        this.$refs.form.validateField("deposit");
                        return;
                    }

                    this.data = data;
                    this.$refs.form.validateField("rent");
                    this.$refs.form.validateField("deposit");
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        checkRent(rule, value, callback) {
            let data = this.data;
            if ('' === value) {
                callback();
                return;
            }

            this.checkMoney(rule, value, (err) => {
                if (err) {
                    callback(err);
                } else {
                    if ((typeof data.freeDeposit) === 'undefined' || data.freeDeposit === null || data.freeDeposit === '-') {
                        callback(new Error('分包最大可退租金数据缺失'));
                    } else {
                        let rent = new BigDecimal(value);
                        let freeRent = new BigDecimal(data.freeRent);
                        if (rent.compareTo(freeRent) > 0) {
                            callback(new Error('超过最大可退租金'));
                        } else {
                            callback();
                        }
                    }
                }
            });
        },
        checkDeposit(rule, value, callback) {
            let data = this.data;
            if ('' === value) {
                callback();
                return;
            }

            this.checkMoney(rule, value, (err) => {
                if (err) {
                    callback(err);
                } else {
                    if ((typeof data.freeDeposit) === 'undefined' || data.freeDeposit === null || data.freeDeposit === '-') {
                        callback(new Error('分包最大可退押金数据缺失'));
                    } else {
                        let deposit = new BigDecimal(value);
                        let freeDeposit = new BigDecimal(data.freeDeposit);
                        if (deposit.compareTo(freeDeposit) > 0) {
                            callback(new Error('超过最大可退押金'));
                        } else {
                            callback();
                        }
                    }
                }
            });
        },
    }
};
</script>

<style scoped>
</style>